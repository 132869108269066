import React from 'react'

const NotFound = () => {
    return (
        <>
            <style>{`
                    .full-height {
                        height: 100vh;
                    }
        
                    .flex-center {
                        align-items: center;
                        display: flex;
                        justify-content: center;
                    }
        
                    .position-ref {
                        position: relative;
                    }
        
                    .code {
                        border-right: 2px solid;
                        font-size: 26px;
                        padding: 0 15px 0 15px;
                        text-align: center;
                    }
        
                    .message {
                        font-size: 26px;
                        text-align: center;
                        padding: 10px;
                    }
            `}</style>
            <div className="flex-center position-ref full-height">
                <div className="code">
                    404
                </div>

                <div className="message">
                    Not Found
                </div>
            </div>
        </>
    )
}

export default NotFound